<template>
  <v-content>
    <div class="pa-4">
      <span class="fontheaderBold">สรุปใบสั่งซื้อ</span>
    </div>
    <div v-for="(item, index) in cart.products" :key="index">
      <v-row class="ma-0">
        <v-col :cols="3" class="borderBox">
          <v-img :src="item.image" contain width="100" height="100" class="ml-1" />
        </v-col>
        <v-col :cols="9" class="borderBox">
          <div class="d-flex align-center justify-space-between">
            <div>
              <span class="titleName">{{ item.name }}</span>
              <span class="description">{{ item.description }}</span>
              <span class="headerBold" style="font-size: 0.9rem"> จำนวน: {{ item.qty }} </span>
            </div>
            <div>
              <span class="titleName">THB {{ item.subtotal.toLocaleString() }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-container class="pa0">
      <div class="d-flex justify-space-between">
        <span class="fontheaderBold"> ยอดรวม</span>
        <span class="fontheaderBold"> THB {{ cart.grand_total.toLocaleString() }} </span>
      </div>
    </v-container>
    <v-divider></v-divider>
    <v-container>
      <div>
        <span class="fontheaderBold">พื้นที่จัดส่ง</span>
        <span class="fontheaderBold" style="font-size: 0.95rem !important; color: #707070">
          {{ contact }}
        </span>
        <span class="fontheaderBold" style="font-size: 0.95rem !important; color: #707070">
          {{ address }}
        </span>
      </div>
    </v-container>
    <v-divider></v-divider>
    <v-container>
      <div class="my-3">
        <span class="fontheaderBold">ทางเลือกชำระเงิน</span>
        <v-select
          v-model="paymentType"
          :items="paymentOption"
          filled
          label="โปรดเลือกการชำระเงิน"
          hide-details
        ></v-select>
      </div>
      <div class="my-3">
        <v-text-field label="คำอธิบายเพิ่มเติม" hide-details filled v-model="description" />
      </div>
      <div class="mb-12 mt-3">
        <v-dialog
          ref="dialog"
          v-model="isShowDatePicker"
          :return-value.sync="deliveryDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              filled
              v-model="deliveryDate"
              label="โปรดเลือกวันที่จัดส่ง"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="deliveryDate" scrollable :min="minDate">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="isShowDatePicker = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(deliveryDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </v-container>
    <Button title="ยันยันสั่งสินค้า" :func="onClickConfirmCart"></Button>
  </v-content>
</template>

<script>
import Button from '../components/Button'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ConfirmCart',
  components: {
    Button,
  },
  data: () => {
    return {
      paymentType: null,
      description: '',
      paymentOption: [
        {
          text: 'เงินสด (Cash)',
          value: 'cash',
        },
        {
          text: 'เครดิต (Credit)',
          value: 'credit',
        },
      ],
      isShowDatePicker: false,
      deliveryDate: null,
      minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    }
  },
  methods: {
    ...mapActions(['getCart']),
    async confirmCart() {
      const res = await this.$axios({
        method: 'POST',
        url: '/api/cart/confirm',
        params: {
          social_id: this.$store.state.tokenId,
          store_id: this.$store.state.storeId,
          payment_type: this.paymentType,
          description: this.description,
          delivery_date: this.deliveryDate,
        },
      }).then(res => res.data)
      this.$store.commit('setSO', res)
      this.$router.push('/ordercompleted')
    },
    async onClickConfirmCart() {
      const isValidForm = this.validation()
      if (!isValidForm) {
        this.$modal.show({
          title: 'กรุณาเลือกการชำระเงิน และวันที่จัดส่ง',
          isOne: true,
        })
      } else {
        try {
          this.$loading(true)
          if (this.paymentType === 'credit') await this.checkCredit()
          await this.confirmCart()
        } catch (e) {
          if (e.response?.data?.check_stock_results) {
            let arrProductName = []
            e.response.data?.check_stock_results.forEach(v => arrProductName.push(v.product_name))
            this.$modal.show({
              title: `ขออภัยสินค้า <br/>${arrProductName.join('<br/>')} ไม่เพียงพอ`,
              isOne: true,
              isShowDanger: true,
            })
          } else if (e === 'CREDIT_NOT_ENOUGH') {
            this.$modal.show({
              title: `WARNING: ขออภัยเครดิตของคุณไม่เพียงพอ`,
              isOne: true,
              isShowDanger: true,
              onConfirm: () => this.confirmCart(),
            })
          } else {
            this.$modal.show({
              title: `ERROR: ${e?.response?.data?.message || e}`,
              isOne: true,
              isShowDanger: true,
            })
          }
        } finally {
          this.$loading(false)
        }
      }
    },
    async checkCredit() {
      const credit = await this.$axios({
        method: 'GET',
        url: `/api/store/getCredit/${this.storeId}`,
      }).then(r => r.data)
      const totalCartPrice = this.$store.state.cart?.grand_total || 0
      if (credit.balance - totalCartPrice < 0) {
        throw 'CREDIT_NOT_ENOUGH'
      }
    },
    validation() {
      return !!this.paymentType && !!this.deliveryDate
    },
  },
  computed: {
    ...mapState(['cart', 'storeId']),
    contact() {
      return `${this.cart.merchant_detail[0].name_store} ( ${this.cart.merchant_detail[0].account_phone_1} )`
    },
    address() {
      /*eslint-disable */
      const {
        address_1,
        subdistrict,
        district,
        province,
        postal_code,
      } = this.cart.merchant_detail[0].address
      const districtName = province === 'กรุงเทพมหานคร' ? `เขต ${district}` : `อำเภอ ${district}`
      const subDistrictName =
        province === 'กรุงเทพมหานคร' ? `แขวง ${subdistrict}` : `ตำบล ${subdistrict}`
      return `${address_1} ${subDistrictName} ${districtName} จังหวัด ${province} ${postal_code}`
    },
  },
  async mounted() {
    try {
      this.$loading(true)
      const cart = await this.getCart()
      this.$store.commit('setCart', cart)
    } catch (e) {
      this.$modal.show({
        title: `ERROR: ${e?.response?.data?.message || e}`,
        isOne: true,
      })
    } finally {
      this.$loading(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.borderBox {
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
